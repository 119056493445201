<template>
  <div class="flex">
    <DatePicker type="month"
                :value="beginMonth"
                placeholder="请选择开始月份"
                @on-change="handleChangeBeginMonth"
                :options="startMonthOptions"
                :clearable="false"
                :editable="false"
                :style="itemStyle"></DatePicker>
    <DatePicker type="month"
                :value="endMonth"
                placeholder="请选择结束月份"
                @on-change="handleChangeEndMonth"
                :options="endMonthOptions"
                :clearable="false"
                :editable="false"
                :style="itemStyle"></DatePicker>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    itemStyle: Object,    // 选择器样式
    dateMonthrange: Array // [开始月份，结束月份]
  },
  data () {
    return {
      beginMonth: "", // 选择的开始年月
      endMonth: "", // 选择的结束年月
      // 限制可选的开始年月
      startMonthOptions: {
        disabledDate: date => {
          if (this.endMonth) {
            let endDate = this.getDate(this.endMonth);
            return (date && date > endDate) || date > new Date();
          } else {
            return date && date > new Date();
          }
        }
      },
      // 限制可选的结束年月
      endMonthOptions: {
        disabledDate: date => {
          if (this.beginMonth) {
            let beginDate = this.getDate(this.beginMonth);
            return (date && date < beginDate) || date > new Date();
          } else {
            return date && date > new Date();
          }
        }
      },
      isDay: true
    };
  },
  watch: {
    dateMonthrange (newVal) {
      this.beginMonth = newVal[0]
      this.endMonth = newVal[1]
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
    },
    // 获取开始月份
    handleChangeBeginMonth (month) {
      this.beginMonth = month;
      if (this.beginMonth && this.endMonth) {
        this.onChange()
      }
    },
    // 获取结束月份
    handleChangeEndMonth (month) {
      this.endMonth = month;
      if (this.beginMonth && this.endMonth) {
        this.onChange()
      }
    },
    onChange () {
      let beginArr = this.beginMonth.split('-')   // 开始月份转化为数组
      let beginDayArr = this.getYearOrMonthForMonth(beginArr[0], beginArr[1])   // 开始月份第一天、最后一天数组
      let endArr = this.endMonth.split('-')   // 开始月份转化为数组
      let endDayArr = this.getYearOrMonthForMonth(endArr[0], endArr[1])   // 开始月份第一天、最后一天数组
      let obj = {
        month: [this.beginMonth, this.endMonth],    // [开始年月,结束年月]
        day: [beginDayArr[0], endDayArr[1]]         // [开始年月日,结束年月日]
      }
      this.$emit('onChange', obj)
    },
    // 字符串转日期
    getDate (strDate) {
      return new Date(strDate + '-01')
    },
    // 根据传入的年月获取当前月第一天至最后一天
    getYearOrMonthForMonth (year, month) {
      let currentDate = new Date()
      let currentMonth = currentDate.getMonth() + 1
      let currentday = currentDate.getDate()
      let endDateDay
      let dateArr = []
      // 如果月份等于当前月份，则最后一天为当日
      if (month == currentMonth) {
        endDateDay = currentday
      } else {
        endDateDay = new Date(year, month, 0).getDate()
      }
      if (parseInt(endDateDay) < 10) {
        endDateDay = '0' + endDateDay
      }
      console.log(endDateDay);
      let startDate = year + "-" + month + "-01"
      let endDate = year + '-' + month + '-' + endDateDay
      dateArr.push(startDate)
      dateArr.push(endDate)
      return dateArr
    }
  },
}
</script>

<style lang='scss' scoped>
</style>